import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    condition: {},
  },
  getters: {
    getCondition (state) {
      return state.condition
    },
  },
  mutations: {
    updateCondition (state, condition) {
      state.condition = condition
    },
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/financeresourcerelation/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/financeresourcerelation/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/financeresourcerelation/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/financeresourcerelation/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/financeresourcerelation/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    getListByCompanyId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/financeresourcerelation/getListByCompanyId', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    searchAccountByStoreIdAndCompanyId(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/financeresourcerelation/searchAccountByStoreIdAndCompanyId', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
