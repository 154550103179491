<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-card

        >
          <b-form
                  @submit.prevent="searchByCondition"
                  @reset.prevent="resetCondition"
          >
            <b-row>
              <!-- 查询条件     -->
              <!--收款单位-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="收款单位:"
                        label-for="company_name"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.companyName"
                          name="company_name"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="收款单位"
                  />
                </b-form-group>
              </b-col>
              <!--收款账号-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="收款账号:"
                        label-for="account_id"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.accountNo"
                          name="account_id"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="收款账号"
                  />
                </b-form-group>
              </b-col>
              <!--店铺团队-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="店铺团队:"
                        label-for="store_name"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.storeName"
                          name="store_name"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="店铺团队"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>


          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                      variant="primary"
                      :to="{ name: 'apps-financeresourcerelation-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->


        <!-- Column: Type -->
        <template #cell(${classVar}_type)="data">
          {{getCodeLabel('financeresourcerelation_type', data.item.financeresourcerelation_type)}}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator)}}  {{ toDate(data.item.add_time)}}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator)}}  {{ toDate(data.item.modify_time)}}
        </template>


        <template #cell(account_id)="data">
          {{data.item.ext['accountNo']}}
        </template>



        <!-- Column: State -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-financeresourcerelation-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeState(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import financeresourcerelationUseList from './financeresourcerelationUseList'
import financeresourcerelationStore from './financeresourcerelationStore'
import XyInputButton from "@/views/components/xy/XyInputButton";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton
  },
  methods: {
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('financeresourcerelation/updateCondition', this.state.condition)
      this.refetchData()
    },
    changeState: function (data) {
      const state = 0
      store.dispatch('financeresourcerelation/state', {id: data.id, state: state}).then(res => {
        this.refetchData()
      })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('financeresourcerelation')) store.registerModule('financeresourcerelation', financeresourcerelationStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('financeresourcerelation')) store.unregisterModule('financeresourcerelation')
    })

    const toast = useToast()

    onMounted(() => {
      state.condition = store.getters['financeresourcerelation/getCondition']
    })

    const state = reactive({
      condition: {},
    })


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = financeresourcerelationUseList()

    return {
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
